import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import NorthstarPageLayout from '../../../components/NorthstarPageLayout';
import { Document, Page, pdfjs } from 'react-pdf';
import northstarClient from '../../../services/northstarClient';
import leftArrow from "../../../assets/left.png"
import rightArrow from "../../../assets/right.png"
import type { PDFDocumentProxy } from 'pdfjs-dist';
import ElevateButton from '../../../components/ElevateButton';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js`;

interface PublicationDocument {
    name: string;
    id: number;
    createdAt: string;
    document?: string;
    mediaDocument?: any;
    data?:any;
};


const Publication: React.FC = () => {
    const [name, setName] = useState<string | null>(null);
    const [id, setId] = useState<string | null>(null);
    const [doc, setDoc] = useState<PublicationDocument | null>(null);
    const [url, setUrl] = useState<string | null>();
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const GATSBY_API_URL = process.env.GATSBY_API_URL;

    const getPublication = async (id: string) => {
        const publication = await northstarClient.get(`${GATSBY_API_URL}/api/northstar-publication/get-doc?id=${id}`);
        setDoc(publication[0]);
        setUrl(publication[0].url)
    }

    useEffect(() => {
        try {
            const queryParams = new URLSearchParams(window.location.search)
            const name = queryParams.get("name");
            const id = queryParams.get("id");

            if (id) getPublication(id);

            setName(name);
            setId(id);

        }
        catch (e) {
            // TODO: ERROR NOTIFICATIONS
        }

    }, []);

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy) => {
        setNumPages(nextNumPages);
    }

    const changePage = (offset: number) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    return (
        <NorthstarPageLayout title={name || ""}>
            <>
                {
                    !!doc &&
                    <div className='flex items-center static mb-5'>
                        <div className='text-center mb-5 font-bold w-full text-lg md:text-xl my-6 relative left-[58px]'>{name}</div>
                        {
                            url && <ElevateButton className='text-sm p-1 relative'><a href={url} download="file" target="_blank">Download</a></ElevateButton>
                        }

                    </div>
                }
                {
                    !!doc?.data?.document &&
                    <ReactMarkdown
                        className="prose text-center text-base" children={doc.data.document}
                        rehypePlugins={[rehypeRaw]}
                        components={{ u: ({ node, ...props }) => <u style={{ color: "#FF6633" }} {...props} /> }}
                    />
                }
                {
                    <div>
                        <div>
                            {
                                !!doc?.mediaDocument && doc?.mediaDocument?.ext === ".pdf" &&
                                <div>
                                    <Document
                                        file={{ data: new Uint8Array(doc?.mediaDocument?.data) }}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page
                                            pageNumber={pageNumber}
                                            canvasBackground="#F5F5F5"
                                        />
                                    </Document>
                                    <div className='flex flex-col items-center justify-center mt-5'>
                                        <div>
                                            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                                        </div>
                                        <div className='mt-3'>
                                            <button
                                                type="button"
                                                disabled={pageNumber <= 1}
                                                onClick={previousPage}
                                                className="mr-2"
                                            >
                                                <img src={leftArrow}></img>
                                            </button>
                                            <button
                                                type="button"
                                                disabled={pageNumber >= (numPages || 0)}
                                                onClick={nextPage}
                                                className="ml-2"
                                            >
                                                <img src={rightArrow}></img>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </>
        </NorthstarPageLayout>
    )
}

export default Publication